import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {appModule} from '@/store/app';
import {officialModule} from '@/store/official';
import {managerLoaderModule} from '@/store/manager/loader';

import MoIntroFinish from '@/components/part/official/MoIntroFinish.vue';
import {prodWizardModule} from "@/store/prod/wizard";
import {prodStartModule} from "@/store/prod/start";

@Component({
    components: {
		MoIntroFinish,
    }
})
export default class TeIntro extends Vue {

	public waitLoadingInterval: any;

    public slideData: any = [];

	//Intro Scean Number
	public intro: number = 0;
	public introTitle: number = 0;

	//SKIPした
	public isSkiped: boolean = false;

	//最初のDelay
	public introDelay: number = 1200;

	//1シーンの時間
	public introScean: number = 2500;
	public introSceanDelay: number = 400;
	public introSceanReady: number = 100;

	// $t-in-q + $t-in-body-delay
	public introSceanOutDur: number = 200;

	//Introが再生済み/Skip済みかどうか
	public isIntroWatched: boolean = officialModule.introWatched;

	//イントロの画面の切り替わり時のチラツキを防止するために少し早く表示させるためのスイッチ
	public isIntroEndedDisplay: boolean = false;


    public async mounted() {

        // 入力内容一時保存のロード
        await prodStartModule.load();

        //Loadingが終わったら再生する
        this.waitLoadingInterval = setInterval(() => {
            if (!this.isLoading) {
                this.playIntro();
                clearInterval(this.waitLoadingInterval);
            }
        }, 50);
    }

	// Destoryed ////////////////////////////////////////////////////////////////////
    public destoryed(){
        clearInterval(this.waitLoadingInterval);
    }

	// Computed ////////////////////////////////////////////////////////////////////
//     public get sitename(): string {
//         return prodStartModule.input.sitename;
//     }

	public get deviceSize(): any {
    	return appModule.deviceSize;
	}

	public get isLoading(): boolean {
    	return managerLoaderModule.loading;
	}

	// Events //////////////////////////////////////////////////////////////////////
//     public async onInput(e: any) {
//         await prodStartModule.updateInput({
//             [e.target.name]: e.target.value,
//         });
//     }

//     public async onClickStart() {
//         await prodStartModule.save();
//         this.$router.push('/official/layout');
//     }

	// Methods //////////////////////////////////////////////////////////////////////
    public playIntro() {

        if(!this.isIntroWatched){

			//Start Delay
	        setTimeout( () => {
				if(!this.isSkiped) {
    				this.intro = 1;
    				this.introTitle = 1;

                    //TAKE
        	        setTimeout( () => {
        				if(!this.isSkiped) {

            				//Delay
            				this.intro = 0;
            				this.introTitle = 0;

                			//TAKE START
                	        setTimeout( () => {

                				if(!this.isSkiped) {
                					this.intro = 2;
                                    this.introTitle = 2;

                                    //Ready
                            	        setTimeout( () => {
                            				if(!this.isSkiped) {
                            					this.intro = 0;
                                            this.introTitle = 0;

                                    			//Write
                                    	        setTimeout( () => {
                                    				if(!this.isSkiped) {
                                    					this.intro = 3;
                                                    this.introTitle = 3;

                                            	        setTimeout( () => {
                                            				if(!this.isSkiped) {
                                    					        this.introEnd();
                                                        }

                                                    }, this.introScean - this.introSceanOutDur + this.introSceanReady);
                                    				}
                                            }, this.introSceanDelay);
                            				}
                                    }, this.introScean - this.introSceanOutDur + 600);
                				}
                            }, this.introSceanDelay);
                        }
                    }, this.introScean - this.introSceanOutDur);
                }
            }, this.introDelay);

        } else {
            this.introEnd();
        }
    }


    //Skipしたとき
    public introSkip() {
	    this.isSkiped = true;
	    this.introEnd();
    }

    //見終えたとき
    public introEnd() {
        this.isIntroEndedDisplay = true;
        this.introTitle = 0;
        this.intro = 0;

        setTimeout( () => {
            this.isIntroWatched = true;
            officialModule.updateIntroWatched(true);

            setTimeout( () => {
                this.introTitle = 4;

//                 setTimeout( () => {
//                     this.storenameGo = true;
//
//                 }, 4000);

                setTimeout( () => {
                    this.introTitle = 5;
                }, 6000);

            }, 2200);
        }, 1);
    }

}


