import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import RoutingUtil from "@/classes/view/routing-util";
import APage from '@/classes/page/a-page';
import {themeMainModule} from '@/store/theme/theme-main';
import {appModule} from '@/store/app';
import {officialModule} from '@/store/official';
import TeIntro from '@/components/part/teaser/TeIntro.vue';
import {Popup} from '@/configs/popup';
import {prodWizardModule} from '@/store/prod/wizard';

import * as $ from 'jquery';
import {TweenMax} from 'gsap';

@Component({
	layout: 'teaser',
    components: {
		TeIntro,
    }
})
export default class Index extends APage {

	public title: string = 'THOUGHT｜ブランディングツール';

    ///////// 全ページ共通 ////////////////////////////////////////////
	public mypagetype: string = 'official-home';
	public mydivider: string = 'full';


    // ヘッダタグの構成要素
    public head() {
        return {
            title: this.title
        }
    }


    ///////// 全ページ共通 ////////////////////////////////////////////
    public transition(to: any, from: any) {
		return RoutingUtil.transition(to, from);
	}


    public created(){

		///////// 全ページ共通 ////////////////////////////////////////////
		this.setPagetype( appModule, this.mypagetype, this.mydivider );
    }


    public mounted() {
        //console.log('popup1 > ', Popup.findByKey('id', 'account-card-change'));
        //console.log('popup2 > is null > ', Popup.findByKey('id', 'unko'));

        prodWizardModule.load();
    }

    ///////// このページ ////////////////////////////////////////////
	public get deviceSize(): any {
    	return appModule.deviceSize;
	}

	public get mainFrameResized(): any {
	    return themeMainModule.mainFrameResized;
	}

}




