import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {appModule} from '@/store/app';

@Component
export default class MoIntroFinish extends Vue {

    public startDelay: number = 0;
    public loopDuration: number = 6600;

    public slideLength: number = 3;
    public slide: number = 0;
    public loopInterval: any;

    public mounted() {

        setTimeout( () => {
            this.loop();
        }, this.startDelay);
    }

    public loop() {

        this.loopInterval = setInterval( () => {

            ++this.slide;
            if (this.slide >= this.slideLength) {
                this.slide = 0;
            }
        }, this.loopDuration);

    }

}
